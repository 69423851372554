@import '../../../styles/globalVariables';
@import '../../../styles/mixins';
@import '../../../styles/fx.scss';

.modelOverlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 45, 57, 0.4);
  z-index: 99999;
  &.termBackT{
    overflow-y: auto;
    align-items:inherit;
  }
}

.contact_us {
  color: #222222;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  text-align: left;
  @include for-size(phone-only) {
    font-weight: 500;
    color: #191919;
  }
  html[dir='rtl'] & {
    text-align:right;
  }
  @include for-size(phone-only) {
    padding-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  
}

.flex {
  padding-top: 46px;
  display: flex;
  justify-content: left;
  align-items: center;
  html[dir='rtl'] & {
    justify-content: revert;
  }
  @media screen and (max-width: 767px) {
    padding-top: 51px;
  }
}

.call_logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include for-size(phone-only){
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
}
.call_circle {
  border-width: 2px;
  border-radius: 100%;
  padding: 0.4px;
  margin-right: 1rem;
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  [dir='rtl'] & {
    margin-left: 1rem;
    margin-right: 0;
  }
  @include for-size(phone-only) {
    margin:0;
  }
}

.call_us {
  letter-spacing: 0.4rem;
  font-weight: 800;
  color: #262626;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.5rem;
  @include for-size(phone-only) {
    // Mobile CSS
    font-weight: 800;
    font-size: 12px;
    margin-bottom: 17px;
    margin-top: 17px;
  }
  [dir='rtl'] & {
    letter-spacing: 0;
  }
}

.number {
  font-weight: 700;
  font-size: 25px;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  @include for-size(phone-only) {
    font-size: 22px;
  }
}
.text2 {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));

  @media screen and (max-width: 767px) {
    font-size: 13px;
    font-weight: 400;
  }
}

.model {
  max-width: 637px;
  width: 100%;
  overflow-y: auto;
  background: #fff;
  padding: 24px 32px 32px 32px;
  border-radius:12px;
  border: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;  
  position: relative;
  margin-top: 64px;
  @include for-size(phone-only) {
    margin-top: 0;
    &.Auctionswork{
      top: initial;
      height: auto;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding-bottom: 24px;
      padding-top: 16px;
      .HeaderTitle{
        justify-content: left;
        margin-bottom: 24px;
        html[dir='rtl'] & {
          justify-content: right;
        }
        button{
          display: none;
        }
      }
      .cancle_btn{
        display: block;
        right: 12px;
        top: 12px;
        html[dir='rtl'] & {
          right: inherit;
          left: 12px;
        }
    
      }
  }
  }
  &.termCdnModal{
    max-width: 904px;
    padding: 28px 62px 28px 42px;
    .contact_us{
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      color: #222222;
      @include for-size(phone-only){
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #191919;
      }
    }
    .cancle_btn{
      right: 20px;
      top: 20px !important;
      @include for-size(phone-only){
        top: 14px !important; 
      }
      html[dir='rtl'] & {
        right: inherit;
        left: 20px;
      
      }
    }
    html[dir='rtl'] & {
      font-family: $arabic-font;
      padding: 28px 42px 28px 62px;
      @include for-size(phone-only){
        padding: 14px 16px 28px 16px;
      }
      .HeaderTitle{
        justify-content: right;
      }     
    }
    @include for-size(phone-only) {
      padding:14px 16px;
      .cancle_btn{
        display: block;
      }
      .HeaderTitle{
        justify-content: left;
        button{
          display: none;
        }
      }
      padding-top: 14px;
    }
  }

  .HeaderTitle{    
    button{
      display: none;
      position: absolute;
      left: 18px;
      background: transparent;
      border: 0;
      html[dir='rtl'] & {
        right: 18px;
        left: inherit;
        transform: rotate(180deg);
      }
      @include for-size(phone-only) {
        display: block;
      }
    }
    @include for-size(phone-only) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 22px;
    }
  }
  .mTitle{
    display: none;
    font-weight: 600;
    font-size: 20px;
    color: #262626;    
    text-align: center;
    @include for-size(phone-only) {
      display: block;
    }
  }

  @include for-size(phone-only) {
    position: absolute;
    bottom: 0;
    width: 100%;
    top:0;
    padding: 30px 16px 48px 16px;
    border-radius:0;
  }
  

  & .cancle_btn {
    position: absolute;
    right:8px;
    top: 8px;    
    padding: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i{
      color:#000000;
      display: flex;
    }
    @include for-size(phone-only) {
      display: none;
    }
    & svg {
      transform: scale(1.5);
    }
  }

  & .step_btn_area {
    padding-top: 24px;
    display: flex;
    justify-content: center;

    & .step_btn {
      background: #6f10a2;
      color: #fff;
      padding: 0.6rem 2rem 0.6rem 2rem;
      border-radius: 0.25rem;
      border: none;
      font-weight: 600;
      cursor: pointer;
      max-height: 48px;
      height: 48px;
      display: flex;
      align-items: center;
    }
    & .step_btn_inactive {
      // background: #6f10a2;
      // color: #fff;
      background-color: rgba(197 94 197 / 15%);
      padding: 0.6rem 2rem 0.6rem 2rem;
      border-radius: 0.25rem;
      border: none;
      font-weight: 600;
      cursor: pointer;
      max-height: 48px;
      height: 48px;
      display: flex;
      align-items: center;
    }
  }

  & .number_area {
    // display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  & .heading {
    font-size: 30px;
    color: #6f10a2;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
    @include for-size(phone-only) {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 11px;
    }
  }
  & .subheading {
    font-size: 13px;
    font-family: $primary-font;
    text-align: center;
    & b {
      font-weight: 700;
    }
  }
  & .text {
    // General CSS
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: $color-gray;
    text-align: left;
    html[dir='rtl'] & {
      text-align: right;
      
    }
    @include for-size(phone-only) {
      padding-top: 33px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  & .completed {
    // General CSS
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #0c7651;    
  }
  & .numbers {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .numbers_first {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-bottom: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 8px;

      & .content {
        padding: 18px 21px;
        display: grid;
        align-items: center;
        grid-template-columns: 40px 365px 80px;
        @media screen and (max-width: 767px) {
          grid-template-columns: 50px 170px 80px;
        }
        & .icon {
          margin-left: 40px;
        }
        & .iconup {
          margin-left: 25px;
        }
      }

      & .radiobutton {
        height: 24px;
        width: 24px;
        margin-right: 16px;
      }
      & .circles {
        width: 2rem;
        height: 2rem;
        background: #fff;
        color: #6f10a2;
        display: inline-flex;
        align-items: center;
        border: 1px solid #b0b2b5;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
        font-size: 0.75rem;
        font-weight: 600;
      }
      .circlesFill {
        width: 2rem;
        height: 2rem;
        background: #6f10a2;
        color: #fff;
        display: inline-flex;
        align-items: center;
        border: none;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    & .text_xs {
      font-size: 0.75rem;
      line-height: 1rem;
      color: rgba(107, 114, 128);
    }
  }
}


html[dir='rtl'] {
  .model {
    & .numbers {
      & .numbers_first {
        & .circles,
        .circlesFill {
          margin-right: 0px;
          margin-left: 1rem;
        }
      }
    }
    & .cancle_btn {
      right: auto;
      left: 16px;
      top: 10px;
    }
  }
}

.type {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 24px 0px;
  line-height: 24px;
  @include for-size(phone-only) {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}

.margin {
  margin-left: 6px;
  margin-top: 8px;
  @include for-size(phone-only) {
    // Mobile CSS
    margin-left: 6px;
    margin-top: 8px;
  }
}

.bookActive {
  border-color: #6f10a2 !important;
  background-color: rgb(197 94 197 / 7%);
}


// new contact
.cBoxDetails{
  display: flex;
  grid-gap: 36px;
  align-items: center; 
  margin-top: 24px;
  margin-bottom: 4px;
  .imgBoxC{
    order: 2;
    display: flex;
  }
  @include for-size(phone-only) {
    margin-top: 28px;
    justify-content: center;
    grid-gap: 0;
    flex-flow: wrap;
    align-items: flex-start;
    .imgBoxC{
      order: 0;
    }
  }
    .contactBoxT{
    max-width: 311px;
    @include for-size(phone-only) {
      margin:39px auto 0;
    }
    .cTextPera{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #737373;
      @include for-size(phone-only) {
        text-align: center;
      }
    }
      .cntBtn{
        background: #6F10A2;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 8px;
        margin-top: 24px;
        height: 44px;
          span{
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
          }
      }
    }
}
.Howwork{
  margin-bottom: auto;
  max-width: 576px;
  ul{
    display: flex;    
    @include for-size(phone-only) {
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #F3F6FA;
      background: #FFF;
      padding: 20px 16px;
    }
  }
  li{
    width: 100%; 
    @include for-size(phone-only) {
      display: flex;
    }
    .iconBox{
      width: 40px;
      height: 40px;
      background: rgba(80, 101, 116, 0.05);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
    } 
   
  }
  .Title {
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px;
    @include for-size(phone-only) {
      margin-top: 0;
      margin-left: 16px;
      html[dir=rtl] &{
        margin-right: 16px;
        margin-left: auto;
    }
    }
  }
  .TitleNumber {
    color: #707B89;
    font-weight: 400;
  }
  .TitleInfo {
    color: #1D252D;
    font-weight: 500;
  }
}
.videoBox{
  margin-top: 16px;
  margin-bottom: 24px;
  width: 576px;
  display: flex;
  flex-flow: wrap;
  @include for-size (phone-only){
    width: 100%;
    margin-bottom: 16px;
  }
  .videoContainer{
    width: 100%;
    height: 324px;
    border-radius: 8px;
    @include for-size(phone-only) {
      height: 196px;
    }
  }
  }

.centered_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 210px;
}

.closeBtn {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  html[dir=rtl] &{
    left: 8px;
    right: auto;
} 
}

.HowAuctionWorkBody {
  @include for-size(phone-only) {
    display: inherit !important;
    background: #F3F6FA;
    max-height: 100% !important; 
  }
}

.dashline {
  flex-grow: 1;
  margin: 0px 2px;
  height: 1px;
  border: none;
  background: repeating-linear-gradient(90deg, #E1E4E8, /* Line color */ #E1E4E8 4px, /* Length of the colored dash */ transparent 4px, transparent 6px /* Gap between dashes */);
  @include for-size(phone-only) {
    width: 1px;
    height: 28px;
    background: repeating-linear-gradient(180deg, #E1E4E8, /* Line color */ #E1E4E8 4px, /* Length of the colored dash */ transparent 4px, transparent 6px /* Gap between dashes */);
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  @include for-size(phone-only) {
    flex-direction: column;
  }
}