@import '../../../styles/globalVariables';
.FaqAccBox{
    max-width: 576px;
    width: 100%;
    padding-top: 24px;
    display: flex;
    flex-flow: wrap;
    grid-row-gap: 28px;
    @include for-size(phone-only) {
        grid-row-gap: 0;
        padding-top: 16px;
    }
}
.eWalletFaq{    
    width: 100%;
    @include for-size(phone-only){
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #F0EBF9;
        &:last-child{
            border-bottom: 0;
        }
    }
    .faqTitle{
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 10px;
        width: 100%;
        @include for-size(phone-only){
            align-items: self-start;
        }
        h3{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #191919;
            @include for-size(phone-only) {
                font-size: 14px;
                 line-height: 20px;
            }
        }
        .arrow{
            cursor: pointer;
            
            @include for-size(phone-only){
                margin-top: 2px;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            i{
                display: flex;
                color: #737373;
            }
        }
    }
    .answer{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #4C4C4C;
        display: none;
        margin-top: 8px;
        &.cuatomOl{
            li{
                padding-bottom: 10px;
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }
        @include for-size(phone-only) {
            font-size: 14px;
        }
        &.active{
            display: block;
        }
    }
}

