@import '@/styles/globalVariables';
@import '@/styles/mixins';
@import '@/styles/fx.scss';

// new contact
.cBoxDetails{
  display: flex;
  grid-gap: 16px;
  margin-top: 16px;
  width: 556px;
  justify-content: space-between;
  @include for-size(phone-only) {
    width: 100%;
    padding: 0px 16px;
  }
  .imgBoxC{
    order: 2;
    display: flex;
    img{
      width: 179px;
      height: 112px;
    }
  }
  @include for-size(phone-only) {
    margin-top: 48px;
    justify-content: center;
    grid-gap: 0;
    flex-flow: wrap;
    align-items: flex-start;
    .imgBoxC{
      order: 0;
      img{
        width: 179px;
        height: 112px;
      }
    }
  }
    .contactBoxT{
    max-width: 311px;
    @include for-size(phone-only) {
      margin-top: 36px;
      max-width: 100%;
    }
    .cTextPera{
      color: #1D252D;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      html[dir='rtl'] & {
        padding-right: 0;
      }
      @include for-size(phone-only) {
        text-align: center;
        padding-right: 0;
      }
    }
      .cntBtn{
        background: #6F10A2;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 8px;
        height: 44px;
        width: 155px;
        padding: 0 0;
        @include for-size(phone-only) {
          width: 147px;
          &.fWBtn{
            width: 100% !important;
            margin-top: 24px;
          }
        }
          span{
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
          }
      }
    }
}

.cBoxArea{
  display: flex;
  grid-gap: 16px;
  margin: 24px 0; 
  @include for-size(phone-only) {
    justify-content: center;
    margin-top: 16px;
  }
}
.wBtn{
  border: 1px solid #10A26F;
  border-radius: 12px;
  width: 155px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  cursor: pointer;
  @include for-size(phone-only) {
    width: 148px;
  }
  i{
    color: #10A26F;
  }
  span{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #10A26F;

  }
}
.OtherEnquires{
  .eTitle{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #737373;
    margin-bottom: 8px;
    display: block;

    @include for-size(phone-only) {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    letter-spacing: 0.4em;
      html[dir='rtl'] & {
        line-height: 22px;
        letter-spacing: inherit;
      }
    }
  }
  .eNumber{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6F10A2;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    @include for-size(phone-only) {
      justify-content: center;
    }
  }
}

.cNumberWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 12px;
  @include for-size(phone-only) {
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.cNumber {
  color: #1D252D;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.cNumberText {
  color: #485661;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px; 
  display: flex;
  align-items: center;
}

.mainContainer {
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  .whatAppButton {
      width: 100% ;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 12px;
      white-space: nowrap;
      gap: 4px;
      height: 40px;
      border: 1px solid #F3F9F6;
      background-color: #F3F9F6;
      border-radius: 8px;
      color: #048848;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      @include for-size(tablet-up) {
          width: 100%;
      }

      i {
          display: inline-block;
          vertical-align: middle;
          height: 16px;
      }
  }

  .callCta {
      position: relative;

      .callButton {
          width: 100%;
          padding: 0px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          height: 40px;
          border: 1px solid #F9F5FE;
          background-color: #F9F5FE;
          border-radius: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6F10A2;
          @include for-size(tablet-up) {
              width: auto;
              min-width: 152px;
          }

          i {
              display: inline-block;
              vertical-align: middle;
              height: 16px;
          }

      }

      .toolTip {
          display: none;
          position: absolute;
          top: -56px;
          left: 50%;
          transform: translateX(-50%);
          background: #4A4C4F;
          width: 166px;
          color: #ffffff;
          padding: 8px 20px;
          border-radius: 7px;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.20), 0px 1.1269396543502808px 2.1286637783050537px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

          h6 {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #ffffff;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
          }

          .mobileNumber {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
          }

          &::after {
              content: "";
              position: absolute;
              top:100%;
              left: 50%;
              border-width: 8px;
              border-style: solid;
              border-color: #4a4c4f transparent transparent transparent;
              transform: translateX(-50%);
          }
      }

      &:hover .toolTip {
          display: block;
      }

  }


}
.noHover{
  display: none !important;
}

@include for-size(tablet-up) {
  .isNotMobile {
    display: none !important;
  }
}
@include for-size(phone-only) {
  .isNotDesktop {
      display: none !important;
  }
}